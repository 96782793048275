import React from "react"
import SubscriptionForm from "../subscsription-form"

const FORMID = "5a715e72-99bb-4d4c-a372-1594e87b401d"

const NewsletterSubscription = () => {
  return (
    <div className="w-full">
      <div className="text-jar-3 font-medium text-neutral-12">
        Subscribe to our newsletter
      </div>
      <p className="text-jar-3 text-neutral-11 my-5">
        Get the freshest news and resources for developers, designers and
        digital creators in your inbox each week
      </p>
      <SubscriptionForm formId={FORMID} />
    </div>
  )
}

export default NewsletterSubscription
