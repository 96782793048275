import React from "react"
import { Script } from "gatsby"
import { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner"

export default function SubscriptionForm({
  formId,
  className,
}: {
  formId: string
  className?: string
}) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [intersected, setIntersected] = useState(false)

  useEffect(() => {
    const container = ref.current
    let observer: IntersectionObserver

    if (container) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (container && entry.isIntersecting) {
              setIntersected(true)
              observer.unobserve(entry.target)
            }
          })
        },
        {
          rootMargin: "15% 0px 15% 0px",
          threshold: 0,
        }
      )

      observer.observe(container)
    }

    return () => {
      if (container) {
        observer?.disconnect()
      }
    }
  }, [formId])

  return (
    <>
      {intersected && (
        <Script
          id={`mooform-script-${formId}`}
          dangerouslySetInnerHTML={{
            __html: `if (!window.mootrack) {
              !(function (t, n, e, o, a) {
                function d(t) {
                  var n = ~~(Date.now() / 3e5),
                    o = document.createElement(e);
                  (o.async = !0), (o.src = t + "?ts=" + n);
                  var a = document.getElementsByTagName(e)[0];
                  a.parentNode.insertBefore(o, a);
                }
                (t.MooTrackerObject = a),
                  (t[a] =
                    t[a] ||
                    function () {
                      return t[a].q
                        ? void t[a].q.push(arguments)
                        : void (t[a].q = [arguments]);
                    }),
                  window.attachEvent
                    ? window.attachEvent("onload", d.bind(this, o))
                    : window.addEventListener("load", d.bind(this, o), !1);
              })(
                window,
                document,
                "script",
                "https://cdn.stat-track.com/statics/moosend-tracking.min.js",
                "mootrack"
              );
            }
            mootrack('loadForm', '${formId}');`,
          }}
        />
      )}
      <div ref={ref} data-mooform-id={formId} className={className}>
        <div className="text-center hidden only:block">
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        </div>
      </div>
    </>
  )
}
